import {
  Box,
  Button,
  Group,
  NumberFormatter,
  Table,
  Text,
} from "@mantine/core";

interface IProps {
  first_name: string;
  balance_due: number;
  invoices: any[];
  onPrev: () => void;
  onNext: () => void;
}
function Step2({
  first_name,
  balance_due,
  invoices,
  onPrev,
  onNext,
}: Readonly<IProps>) {
  return (
    <Box pos="relative" w={{ sm: 600 }} my={50}>
      <Text my={40}>
        Hello{" "}
        <Text span fw={700}>
          {first_name}
        </Text>
        ! Your Balance Due is{" "}
        <Text span c="o2Color" fw={700}>
          <NumberFormatter
            prefix="$ "
            value={balance_due}
            thousandSeparator
            fixedDecimalScale
            decimalScale={2}
          />
        </Text>
      </Text>
      {invoices.length === 0 ? (
        <Text my={40}>
          Click{" "}
          <Text span fs="italic">
            Next step
          </Text>{" "}
          to update your card for future payments.
        </Text>
      ) : (
        <Table
          data={{
            head: ["Invoice #", "Amount Due", "Due Date"],
            body: invoices.map((i: any) => [
              i["invoice_id"],
              <NumberFormatter
                key={i["invoice_id"]}
                prefix="$ "
                value={i["amount_due"]}
                thousandSeparator
                fixedDecimalScale
                decimalScale={2}
              />,
              new Date(i["payment_due"]).toLocaleDateString(),
            ]),
          }}
        />
      )}

      <Group justify="flex-end" mt="xl">
        <Button onClick={onPrev}>Prev step</Button>
        <Button autoFocus disabled={balance_due === undefined} onClick={onNext}>
          Next step
        </Button>
      </Group>
    </Box>
  );
}

export default Step2;
