import {
  Alert,
  Box,
  Button,
  Checkbox,
  Group,
  LoadingOverlay,
  TextInput,
  Title,
} from "@mantine/core";
import { hasLength, isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  formatCreditCard,
  formatDate,
  getCreditCardType,
  registerCursorTracker,
  DefaultCreditCardDelimiter,
  DefaultDateDelimiter,
} from "cleave-zen";
import pick from "lodash.pick";
import { useRef, useEffect, useState, lazy, Suspense } from "react";

import PublicEncript from "./PublicEncrypt";
import api from "./api";
import { validCardNumber, validExpirationDate } from "./utils";

const renderLoader = () => <p>...</p>;
const ErrorIcon = lazy(() => import("./ErrorIcon"));
const errorIcon = (
  <Suspense fallback={renderLoader()}>
    <ErrorIcon height={50} />
  </Suspense>
);

const CardIcon = lazy(() => import("./CardIcon"));

type MapCardType = (creditCardType: string) => string;

const mapCardType: MapCardType = (creditCardType: string) => {
  return (
    {
      discover: "Disc",
      mastercard: "MC",
      jcb: "JCB",
      jcb15: "JCB",
    }[creditCardType] ??
    creditCardType.charAt(0).toUpperCase() + creditCardType.slice(1)
  );
};

interface IProps {
  primary_store_id: string;
  user_id: string;
  balance_due: number;
  barcode: string;
  email: string;
  invoices: any[];
  invoices_sets: any[];
  modulus: string;
  exponent: string;
  public_key_token: string;
  onPrev: () => void;
  onSuccess: (data: any) => void;
}

const Step3 = ({
  primary_store_id,
  user_id,
  balance_due,
  barcode,
  email,
  invoices,
  invoices_sets,
  modulus,
  exponent,
  public_key_token,
  onPrev,
  onSuccess,
}: IProps) => {
  const [visible, handlers] = useDisclosure(false);
  const [error, setError] = useState(undefined);

  const form = useForm({
    initialValues: {
      card_number: "",
      raw_card_number: "",
      last_4: "",
      card_number_type: "general",
      expiration_date: "",
      cvv: "",
      first_name: "",
      last_name: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      zip_code: "",
      authorization: false,
    },

    validate: {
      card_number: (value) =>
        validCardNumber(value) ? null : "Invalid Card Number",
      expiration_date: (value: string) =>
        validExpirationDate(value) ? null : "Invalid Expiration Date",
      cvv: hasLength({ min: 3, max: 4 }, "Invalid CVV"),
      first_name: isNotEmpty("Invalid First Name"),
      last_name: isNotEmpty("Invalid Last Name"),
      address_line_1: isNotEmpty("Invalid Address"),
      city: isNotEmpty("Invalid City"),
      state: isNotEmpty("Invalid State"),
      zip_code: isNotEmpty("Invalid Zip Code"),
      authorization: (value) =>
        value
          ? null
          : "Please authorize O2 Fitness Clubs to update your payment profile by checking the box.",
    },
  });

  const inputWrapperOrder: ("label" | "input" | "description" | "error")[] = [
    "label",
    "input",
    "description",
    "error",
  ];

  const defaultProps = {
    mt: "md",
    inputWrapperOrder,
    required: true,
  };

  const cardType = form.getValues().card_number_type;

  const cardNumberInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (cardNumberInputRef.current) {
      return registerCursorTracker({
        input: cardNumberInputRef.current,
        delimiter: DefaultCreditCardDelimiter,
      });
    }
  }, []);

  const expirationDateInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (expirationDateInputRef.current) {
      return registerCursorTracker({
        input: expirationDateInputRef.current,
        delimiter: DefaultDateDelimiter,
      });
    }
  }, []);

  const icon = (
    <Suspense fallback={renderLoader()}>
      <CardIcon cardType={cardType} />
    </Suspense>
  );

  const validateAndSubmit = async () => {
    let result = undefined;
    if (form.validate().hasErrors) {
      return result;
    }

    handlers.open();

    const crypto = new PublicEncript({ modulus, exponent });
    const card_number_enc = await crypto.encrypt(form.getValues().card_number);
    const keys = api.createProfileKeys.filter(
      (key) =>
        ![
          "primary_store_id",
          "user_id",
          "invoices",
          "invoices_sets",
          "public_key_token",
          "card_number_enc",
        ].includes(key),
    );

    const createProfilePayload = {
      primary_store_id,
      user_id,
      invoices,
      invoices_sets,
      public_key_token,
      card_number_enc,
      ...(pick(form.getValues(), keys) as any),
      card_number_type: mapCardType(form.getValues().card_number_type),
      balance_due,
      barcode,
      email,
    };

    api
      .createProfile(createProfilePayload)
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch((error) => {
        console.error(error);
        setError(
          error.response.data.message ||
            error.response.data.ResponseStatus?.Message ||
            "We are unable to process your payment at this time. Please try again later.",
        );
      })
      .finally(() => {
        handlers.close();
      });
  };

  return (
    <Box pos="relative" w={{ sm: 600 }}>
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      {error && (
        <Alert
          variant="light"
          color="red"
          withCloseButton
          onClose={() => setError(undefined)}
          title="Unexepected error occured"
          icon={errorIcon}
          my={20}
        >
          {error}
        </Alert>
      )}
      <Title order={2}>Credit Card*</Title>
      <TextInput
        ref={cardNumberInputRef}
        {...defaultProps}
        label="Card Number"
        key={form.key("card_number")}
        {...form.getInputProps("card_number")}
        onChange={({ target: { value } }) => {
          const formatted = formatCreditCard(value);
          const raw_card_number = value.replace(/\D/g, "");
          form.setFieldValue("raw_card_number", raw_card_number);
          form.setFieldValue("last_4", raw_card_number.slice(-4));
          form.setFieldValue("card_number", formatted.trim());
          form.setFieldValue("card_number_type", getCreditCardType(value));
        }}
        rightSectionPointerEvents="none"
        rightSection={icon}
        rightSectionWidth={60}
        autoFocus
      />

      <Group grow>
        <TextInput
          ref={expirationDateInputRef}
          {...defaultProps}
          label="Expiration Date"
          key={form.key("expiration_date")}
          {...form.getInputProps("expiration_date")}
          placeholder="MM/YY"
          onChange={({ target: { value } }) => {
            const formatted = formatDate(value, {
              datePattern: ["m", "y"],
            });
            form.setFieldValue("expiration_date", formatted);
          }}
        />

        <TextInput
          {...defaultProps}
          label="CVV"
          key={form.key("cvv")}
          {...form.getInputProps("cvv")}
        />
      </Group>

      <Title order={2} mt={30}>
        Name on Card*
      </Title>
      <Group grow>
        <TextInput
          {...defaultProps}
          label="First Name"
          key={form.key("first_name")}
          {...form.getInputProps("first_name")}
        />

        <TextInput
          {...defaultProps}
          label="Last Name"
          key={form.key("last_name")}
          {...form.getInputProps("last_name")}
        />
      </Group>

      <Title order={2} mt={30}>
        Billing Address*
      </Title>
      <TextInput
        {...defaultProps}
        label="Address Line 1"
        key={form.key("address_line_1")}
        {...form.getInputProps("address_line_1")}
      />

      <TextInput
        {...defaultProps}
        required={false}
        label="Address Line 2"
        key={form.key("address_line_2")}
        {...form.getInputProps("address_line_2")}
      />

      <Group grow>
        <TextInput
          {...defaultProps}
          label="City"
          key={form.key("city")}
          {...form.getInputProps("city")}
        />

        <TextInput
          {...defaultProps}
          label="State"
          key={form.key("state")}
          {...form.getInputProps("state")}
        />

        <TextInput
          {...defaultProps}
          label="Zip Code"
          key={form.key("zip_code")}
          {...form.getInputProps("zip_code")}
        />
      </Group>

      <Checkbox
        mt="md"
        label="I authorize O2 Fitness Clubs to update my payment profile with the information provided for all future and past due balances."
        key={form.key("authorization")}
        {...form.getInputProps("authorization", { type: "checkbox" })}
      />

      <Group justify="flex-end" mt="xl">
        <Button onClick={onPrev}>Prev step</Button>
        <Button onClick={validateAndSubmit}>Next step</Button>
      </Group>
    </Box>
  );
};

export default Step3;
