import fastLuhn from "fast-luhn";

export const validExpirationDate = (value: string) => {
  if (!/^\d{2}\/\d{2}$/.test(value)) {
    return false;
  }

  const [month, year] = value.split("/").map(Number);
  const today = new Date();
  const currentYear = today.getFullYear() % 100;
  return (
    (year > currentYear && month >= 1 && month <= 12) ||
    (year === currentYear && month >= today.getMonth() + 1)
  );
};

export const validCardNumber = (value: string) => {
  const digits = value.replace(/\D/g, "");
  return fastLuhn(digits);
};
