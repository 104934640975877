import { Center, Container, Stepper } from "@mantine/core";
import pick from "lodash.pick";
import { useState } from "react";

import FinalStep from "./FinalStep";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

function App() {
  const [active, setActive] = useState(0);

  const [values, setValues] = useState({
    barcode: "",
    email: "",
    primary_store_id: "",
    user_id: "",
    balance_due: 0,
    invoices: [],
    invoices_sets: [],
    modulus: "",
    exponent: "",
    public_key_token: "",
    card_number: "",
    last_4: "",
    card_number_type: "general",
    expiration_date: "",
    cvv: "",
    first_name: "",
    last_name: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    zip_code: "",
    authorization: false,
    card_number_enc: "",
  });

  const nextStep = () =>
    setActive((current) => {
      return current < 3 ? current + 1 : current;
    });

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  return (
    <Container fluid>
      <Center>
        <Container size={600}>
          <Stepper active={active} orientation="vertical">
            <Stepper.Step label="First step" description="Find your Account">
              <Step1
                {...pick(values, ["barcode", "email"])}
                onMatch={(data) => {
                  setValues(data);
                  nextStep();
                }}
              />
            </Stepper.Step>

            <Stepper.Step label="Second step" description="Confirm Details">
              <Step2
                {...pick(values, ["first_name", "balance_due", "invoices"])}
                onPrev={prevStep}
                onNext={nextStep}
              ></Step2>
            </Stepper.Step>

            <Stepper.Step
              label="Final step"
              description="Enter Payment Information"
            >
              <Step3
                {...pick(values, [
                  "primary_store_id",
                  "user_id",
                  "balance_due",
                  "invoices",
                  "invoices_sets",
                  "modulus",
                  "exponent",
                  "public_key_token",
                  "barcode",
                  "email",
                ])}
                onPrev={prevStep}
                onSuccess={nextStep}
              />
            </Stepper.Step>
            <Stepper.Completed>
              <FinalStep />
            </Stepper.Completed>
          </Stepper>
        </Container>
      </Center>
    </Container>
  );
}

export default App;
