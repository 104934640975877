function base64URLencode(str: string) {
  return str.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
}

function readModulusAndExponent(xml: string): string[] {
  const xmlValuesRegExp = />([^<]+)<\//g;
  return Array.from(xml.matchAll(xmlValuesRegExp)).map((match) =>
    base64URLencode(match[1]),
  );
}

function bufferToBase64(buffer: ArrayBufferLike) {
  const bytes = new Uint8Array(buffer);
  let binary = "";
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

interface IXmlObject {
  xml: string;
}

interface IModulusAndExponentObject {
  modulus: string;
  exponent: string;
}

// A wrapper for Web Crypto API that reads a public key from XML and encrypts text
class PublicEncrypt {
  modulus: string;
  exponent: string;
  algorithm: { name: string };

  constructor(args: IXmlObject | IModulusAndExponentObject) {
    this.algorithm = { name: "RSA-OAEP" }; // RsaOaepParams
    if ("xml" in args) {
      [this.modulus, this.exponent] = readModulusAndExponent(args.xml);
    } else {
      this.modulus = base64URLencode(args.modulus);
      this.exponent = base64URLencode(args.exponent);
    }
  }

  importXmlKey() {
    const format = "jwk";
    const keyData: JsonWebKey = {
      kty: "RSA",
      e: this.exponent,
      n: this.modulus,
    };
    const algorithm: AlgorithmIdentifier | RsaHashedImportParams = {
      ...this.algorithm,
      hash: "SHA-1",
    };
    const extractable: boolean = false;
    const keyUsages: ReadonlyArray<KeyUsage> = ["encrypt"];

    // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/importKey
    return crypto.subtle.importKey(
      format,
      keyData,
      algorithm,
      extractable,
      keyUsages,
    );
  }

  async encrypt(text: string): Promise<string> {
    const key = await this.importXmlKey();

    // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/encrypt
    const encrypted = await crypto.subtle.encrypt(
      this.algorithm,
      key,
      new TextEncoder().encode(text),
    );

    return bufferToBase64(encrypted);
  }
}

export default PublicEncrypt;
