import { MantineColorsTuple } from "@mantine/core";

// https://mantine.dev/colors-generator/
const o2Color: MantineColorsTuple = [
  "#e3fafe",
  "#d9eff2",
  "#b9dadf",
  "#96c4cc",
  "#79b3bb",
  "#66a7b1",
  "#59a2ad",
  "#468e98",
  "#397e89",
  "#206e78",
];

export default o2Color;
