import { Box, Text } from "@mantine/core";

// FinalStep displays a confirmation message after updating the payment profile.
function FinalStep() {
  return (
    <Box pos="relative" w={{ sm: 600 }} my={50}>
      <Text my={40}>Thanks for updating your payment profile.</Text>
    </Box>
  );
}

export default FinalStep;
