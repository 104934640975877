import {
  Alert,
  Box,
  Button,
  Group,
  LoadingOverlay,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { hasLength, isEmail, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useState, lazy, Suspense } from "react";

import api from "./api";

const ErrorIcon = lazy(() => import("./ErrorIcon"));
const errorIcon = (
  <Suspense fallback={<p>...</p>}>
    <ErrorIcon height={50} />
  </Suspense>
);

interface IStep1Props {
  barcode: string;
  email: string;
  onMatch: (data: any) => void;
}

interface IErrorAlert {
  title: string;
  message: string;
}

const Step1 = ({ barcode, email, onMatch }: IStep1Props) => {
  const [error, setError] = useState<IErrorAlert | undefined>(undefined);
  const form = useForm({
    initialValues: {
      barcode,
      email,
    },

    validate: {
      email: isEmail("Invalid Email"),
      barcode: hasLength(
        { min: 2 },
        "Barcode must include at least 2 characters",
      ),
    },
  });

  const validateAndSearch = () => {
    let result = undefined;
    if (form.validate().hasErrors) {
      return result;
    }

    handlers.open();
    const params = form.getValues();

    api
      .search(params)
      .then(({ data }) => {
        onMatch({ ...params, ...data });
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          setError({
            title: "No Match",
            message:
              "No match found for the provided barcode and email. Please call the club to update your payment information.",
          });
        } else {
          setError({
            title: "An error occurred",
            message: "Review barcode and email and try again.",
          });
        }
        setTimeout(
          () =>
            document
              .getElementsByClassName("mantine-Alert-root")[0]
              .scrollIntoView({ behavior: "smooth" }),
          1000,
        );
      })
      .finally(() => {
        handlers.close();
      });
  };

  const [visible, handlers] = useDisclosure(false);

  const inputWrapperOrder: ("label" | "input" | "description" | "error")[] = [
    "label",
    "input",
    "description",
    "error",
  ];

  const defaultProps = {
    mt: "md",
    inputWrapperOrder,
    required: true,
  };

  const theme = useMantineTheme();

  return (
    <Box pos="relative" w={{ sm: 600 }}>
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Title
        style={{
          color: theme.colors.o2Color[9],
        }}
        mb="16"
      >
        Update Payment Profile
      </Title>
      <Text mb="16">
        Thank you for taking the time to update your payment information that is
        on file for your O2 Fitness membership. Please provide updated credit or
        debit card information below and a team member will confirm with you
        once your membership profile has been updated.
      </Text>
      <Text>
        If you would prefer to have your membership dues drafted from a bank
        account, please visit your club with a voided check to have that set up.
        If you have any questions about your membership, please call or visit
        your{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.o2fitnessclubs.com/locations"
        >
          club location
        </a>{" "}
        for assistance.
      </Text>
      {error && (
        <Alert
          variant="light"
          color="red"
          withCloseButton
          onClose={() => setError(undefined)}
          title={error.title}
          icon={errorIcon}
          my={20}
        >
          {error.message}
        </Alert>
      )}
      <form onSubmit={(e) => e.preventDefault()}>
        <TextInput
          {...defaultProps}
          label="Member Barcode"
          description="Please provide the barcode number from the back of your membership keytag. If you do not have that available, please visit your club to update your payment information."
          key={form.key("barcode")}
          {...form.getInputProps("barcode")}
        />
        <TextInput
          {...defaultProps}
          label="Email"
          key={form.key("email")}
          {...form.getInputProps("email")}
        />

        <Group justify="flex-end" mt="xl">
          <Button loading={visible} type="submit" onClick={validateAndSearch}>
            Next step
          </Button>
        </Group>
      </form>
    </Box>
  );
};

export default Step1;
