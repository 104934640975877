import { createTheme, MantineProvider, TextInput } from "@mantine/core";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./index.css";
import o2Color from "./o2color";
import "./styles";

const theme = createTheme({
  colors: {
    o2Color,
  },
  fontFamily:
    "gill-sans-nova, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji",
  primaryColor: "o2Color",
  primaryShade: 9,
  headings: {
    sizes: {
      h1: {
        fontSize: "18px",
      },
      h2: {
        fontSize: "14px",
      },
    },
  },
  components: {
    TextInput: TextInput.extend({
      vars: (theme, props) => {
        if (props.error !== undefined) return {};
        return {
          root: {
            "--input-asterisk-color": "black",
          },
        };
      },
    }),
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <MantineProvider theme={theme} withGlobalClasses>
      <App />
    </MantineProvider>
  </React.StrictMode>,
);
