import axios from "axios";

const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://fn.dev.o2fitnessclubs.com";

interface Step1Values {
  barcode: string;
  email: string;
}

const search = ({ barcode, email }: Step1Values) => {
  const now = new Date().getTime();
  return axios.get(`${BASE_URL}/search`, {
    params: { barcode, email, now },
  });
};

const createProfilePayload = {
  primary_store_id: "1",
  user_id: "1",
  invoices: [
    {
      invoice_id: "1",
      amount: "100",
      currency: "USD",
      description: "Test",
    },
  ],
  card_number_type: "1",
  public_key_token: "1",
  card_number_enc: "1",
  last_4: "1",
  expiration_date: "1",
  cvv: "1",
  first_name: "1",
  last_name: "1",
  address_line_1: "1",
  address_line_2: "1",
  city: "1",
  state: "1",
  zip_code: "1",
};

type CreateProfilePayload = typeof createProfilePayload;
type CreateProfileKeys = (keyof CreateProfilePayload)[];
const createProfileKeys: CreateProfileKeys = Object.keys(
  createProfilePayload,
) as CreateProfileKeys;

const createProfile = (payload: CreateProfilePayload) => {
  return axios.post(`${BASE_URL}/payment`, payload);
};

const api = {
  search,
  createProfile,
  createProfileKeys,
};

export default api;
